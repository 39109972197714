<template>
  <b-card class="dashboard-card shadow-sm">
    <section>
      <Tooltip :content="'myTooltipTemplate'" :anchor-element="'target'" :position="'right'">
        <h1 class="bold-header-text app-header mb-3"><span :title="tooltipText">Blog </span></h1>
        <p>A range of short articles, sortable by program</p>
        <template v-slot:myTooltipTemplate="{ props }">
          <a>
            <strong>{{ props.title }}</strong>
          </a>
        </template>
      </Tooltip>
      <b-row class="mb-2">
        <b-col md="12">
          <a href="https://www.achc.org/blog/" target="_blank">Click here to view the ACHC Blog!</a>
        </b-col>
      </b-row>
    </section>
  </b-card>
</template>
<style lang="scss" scoped></style>
<script>
import { Tooltip } from "@progress/kendo-vue-tooltip";
import { bus } from "../../../main";

export default {
  components: {
    Tooltip,
  },
  inject: ["tabsWithTooltip"],
  created() {
    bus.$emit("showFullpageOverlay", {
      showLayover: true,
    });
    this.tooltipText = this.tabsWithTooltip.find(({ to }) => to === "NewsBlog")?.tooltip || ""; // injected value
    setTimeout(() => {
      bus.$emit("showFullpageOverlay", {
        showLayover: false,
      });
    }, 1000);
  },
  data() {
    return {
      tooltipText: "",
    };
  },
};
</script>
